import { useLocation } from "react-router-dom";

const NotFound = () => {
  const location = useLocation()
  location.state = { page: '404' }

  return (
    <h1>404 - Pagina não encontrada</h1>
  );
};

export default NotFound;
