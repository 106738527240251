import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useLocation } from "react-router-dom";

import Loading from "../../components/Loading";
import BannerProperty from '../../components/BannerProperty';
import Differential from '../../components/Differential';
import HeaderRecord from '../../components/HeaderRecord';
import { useStyles } from './styles';
import Brief from 'features/components/Brief/Brief';

const RecordPage = () => {
  const classes = useStyles();
  const locationHref = "//meuvivaz.com.br";

  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [realtor, setRealtor] = useState({});
  const [property, setProperty] = useState([]);
  const location = useLocation()
  location.state = { page: 'ficha-do-imovel' }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname.split("/");
      if (path.length === 3) {
        setName(path[1]);
        setUrl(path[2]);
      } else {
        window.location.href = locationHref;
        return false;
      }
    }
  }, []);

  useEffect(() => {
    if (name != null && url !== null) {
      try {
        if (sessionStorage.getItem('realtor') == null || sessionStorage.getItem('property') == null) throw 'Sem informações em cache.';

        const sessCorretor = JSON.parse(sessionStorage.getItem("realtor"));
        if (typeof sessCorretor[name] !== 'undefined') {
          setRealtor(sessCorretor[name]);
        } else {
          throw 'Dados de corretor não encontrados no cache ou os dados divergem do corretor selecionado';
        }

        const sessImovel = JSON.parse(sessionStorage.getItem("property"));
        if (typeof sessImovel[name] !== 'undefined' && sessImovel[name].urlAmigavel === url) {
          setProperty(sessImovel[name]);
        } else {
          throw 'Dados do imóvel não encontrados no cache ou os dados divergem do corretor selecionado';
        }

        setLoading(false);

      } catch (error) {
        console.log(error);
        buscaPorURL();
      }

    }
  }, [name, url]);

  const buscaPorURL = async () => {
    await axios.get(`${process.env.REACT_APP_API_HOST}/apicyrela/api/FCommerce/FCImovel/busca-por-url?urlCorretor=${name}&urlImovel=${url}`, { timeout: 30000 })
      .then(res => {
        if (typeof res.data !== 'undefined' && typeof res.data.success !== 'undefined' && res.data.success) {
          if (typeof res.data.corretor !== 'undefined') {
            setRealtor(res.data.corretor);
            let sessCorretor = {};
            sessCorretor[name] = res.data.corretor;
            sessionStorage.setItem("realtor", JSON.stringify(sessCorretor));
          }

          if (typeof res.data.imovel !== 'undefined') {
            setProperty(res.data.imovel);
            let sesssImovel = {};
            sesssImovel[name] = res.data.imovel;
            sessionStorage.setItem("property", JSON.stringify(sesssImovel));
          }
          setLoading(false);
        } else {
          window.location.href = locationHref;
        }
      });
  }
  useEffect(() => {
    if (property.localizacao && property.bi) {
      window.dataLayer.push({
        'event': 'dados-imoveis',
        'pagepath': window.location.pathname,
        'pageType': 'ficha-do-imovel',
        'imoveis': [{
          'id': property.imovelId,
          'nome': property.nome,
          'bairro': property.localizacao.bairro,
          'cidade': property.localizacao.cidade,
          'estado': property.localizacao.estado,
          'estagio': property.status,
          'tipo': property.bi.tipo,
          'areaprivativa': [property.bi.areaPrivativa[0], property.bi.areaPrivativa[1]],
          'dormitorios': [property.bi.dormitorios[0], property.bi.dormitorios[1]],
          'suites': [property.bi.suites[0], property.bi.suites[1]],
          'vagas': [property.bi.vagas[0], property.bi.vagas[1]],
          'valor': [property.bi.valor[0], property.bi.valor[1]],
          'portfolio': property.bi.portfolio
        }]
      })
    }


  }, [property]);
  return (
    <>
      {loading
        ? <Loading />
        : <>
          {realtor &&
            <HeaderRecord corretor={realtor} imovel={property} nome={name} />
          }
          <BannerProperty imovel={property} />
          <Differential imovel={property} />
          <Brief corretor={realtor} />
        </>
      }
    </>
  );
};

export default RecordPage;
