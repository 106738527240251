import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import banner from '../../../assets/images/banner.png';
import email from '../../../assets/images/e-mail.png';
import facebook from '../../../assets/images/facebook.png';

import iconPlanta from '../../../assets/images/icon-planta.png';
import iconQuartos from '../../../assets/images/icon-quartos.png';
import instagram from '../../../assets/images/instagram.png';
import linkedin from '../../../assets/images/linkedin.png';
import logo from '../../../assets/images/logo.png';
import twitter from '../../../assets/images/twitter.png';

import DialogBrief from '../DialogBrief';
import Featured from '../Featured';
import PropertyRecord from '../PropertyRecord';
import HeaderPhones from '../HeaderPhones'
// import RecentProperties from '../RecentProperties';
import { useStyles } from './styles';

const HeaderRecord = ({ corretor, imovel, nome, ...props }) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // const openDialog = () => {
  //   setOpen(true);
  // };

  const closeDialog = () => {
    setOpen(false);
  };

  const gotoHome = () => {
    if (window) {
      window.location.href = `/${nome}`;
    }
  }
  const selectMainImage = (galery) => {
    const [mainImage] = galery.filter((image, index) => {
      if (image.principal) {
        return image['img1680x530']
      }
    })

    if (mainImage) {
      return mainImage['img1680x530']
    }

    return galery[0]['img1680x530']
  }
  // const openWhatsApp = (telefone) => {
  //   let fone = telefone.replace(/[^\d]/g, '');
  //   window.open(
  //     `https://api.whatsapp.com/send/?phone=55${fone}&text&app_absent=0`
  //   );
  // };

  return (
    <>
      <div className={classes.container}>
        {/* header */}
        <div className={classes.containerHeader}>
          <div className={classes.logoHeader}>
            <Link to={`/${corretor.url}`}>
              <div className={classes.divLogo}>
                <img className={classes.logo} src={logo} alt="logo" />
              </div>
            </Link>
          </div>
          <div className={classes.divHeader} />
        </div>
        {/* image background */}
        <div className={classes.containerBanner}>
          <div
            className={classes.divBanner}
            style={{
              backgroundImage: `url(${banner})`,
            }}
          />
          {/* profile */}
          <div className={classes.containerProfileProperty}>
            <div className={classes.containerProfile}>
              <div className={classes.divProfile}>
                <div className={classes.profilePic}>
                  <div onClick={gotoHome} style={{ cursor: 'pointer' }} className={classes.divPic}>
                    <img src={`${corretor.foto}`} className={classes.pic} />
                  </div>
                </div>
                <div className={classes.profileInfos}>
                  <div className={classes.infosDetails}>
                    <div className={classes.divName}>
                      <span onClick={gotoHome} style={{ cursor: 'pointer' }} className={classes.spanName}>
                        {corretor.nome}
                      </span>
                    </div>
                    <div className={classes.divCreci}>
                      <span className={classes.spanCreci}>CRECI {corretor.creci}</span>
                    </div>
                    <div className={classes.divEmail}>
                      <div className={classes.divImgEmail}>
                        <img className={classes.email} src={email} alt="" />
                      </div>
                      <span className={classes.spanEmail}>
                        {corretor.email}
                      </span>
                    </div>
                    {
                      typeof corretor.telefones !== 'undefined' && corretor.telefones.length &&
                      <div className={classes.phoneHolder}>
                        {corretor.telefones.map((item) => (
                          <HeaderPhones
                            classes={classes}
                            isWhatsapp={!!item.whatsApp}
                            telefone={item.telefone}
                          />))}
                      </div>
                    }
                  </div>
                  <hr className={classes.hr} />
                  <div className={classes.infosSocialMedia}>
                    <div className={classes.socialMediaImages}>

                      {typeof corretor.facebook !== 'undefined' && corretor.facebook !== null && corretor.facebook.trim().length > 0 &&
                        <>
                          <div className={classes.divSocialMediaImage}>
                            <a gtm-action="click" gtm-value="corretor:redes-sociais:facebook" href={`https://facebook.com/${corretor.facebook}`} target="_blank">
                              <img className={classes.email} src={facebook} alt="" />
                            </a>
                          </div>
                        </>
                      }

                      {typeof corretor.instagran !== 'undefined' && corretor.instagran !== null && corretor.instagran.trim().length > 0 &&
                        <>
                          <div className={classes.divSocialMediaImage}>
                            <a gtm-action="click" gtm-value="corretor:redes-sociais:instagram" href={`https://instagram.com/${corretor.instagran}`} target="_blank">
                              <img className={classes.email} src={instagram} alt="" />
                            </a>
                          </div>
                        </>
                      }

                      {typeof corretor.linkedin !== 'undefined' && corretor.linkedin !== null && corretor.linkedin.trim().length > 0 &&
                        <>
                          <div className={classes.divSocialMediaImage}>
                            <a gtm-action="click" gtm-value="corretor:redes-sociais:linkedin" href={`https://linkedin.com/${corretor.linkedin}`} target="_blank">
                              <img className={classes.email} src={linkedin} alt="" />
                            </a>
                          </div>
                        </>
                      }

                      {typeof corretor.twitter !== 'undefined' && corretor.twitter !== null && corretor.twitter.trim().length > 0 &&
                        <>
                          <div className={classes.divSocialMediaImage}>
                            <a gtm-action="click" gtm-value="corretor:redes-sociais:twitter" href={`https://twitter.com/${corretor.twitter}`} target="_blank">
                              <img className={classes.email} src={twitter} alt="" />
                            </a>
                          </div>
                        </>
                      }

                    </div>
                    {/* <div
                      aria-hidden="true"
                      onClick={openDialog}
                    className={classes.socialMediaButton}>*/}
                    {/* <img src={btAnalise} alt="" /> */}
                    {/*<button gtm-action="click" gtm-value="corretor:deixar-um-depoimento" className={classes.button} type="submit">
                        Deixar um depoimento
                      </button>
                    </div> */}
                  </div>
                </div>
                {/* {width > 575 && <RecentProperties />} */}
              </div>
            </div>

            <div className={classes.containeProperty}>
              <div className={classes.divProperty}>
                <div className={classes.propertyPic}>
                  <div className={classes.divPropertyPic}>
                    <div className={classes.propertyPict} style={{
                      backgroundImage: `url(${selectMainImage(imovel.galeria)}?d=751x368)`
                    }} />
                    {/* {width <= 575 && (
                      <div className={classes.propertyIcons}>
                        <img
                          className={classes.favoriteIcon}
                          src={favorite}
                          alt=""
                        />
                        <img src={share} alt="" />
                      </div>
                    )} */}
                  </div>
                  <div className={classes.divPropertyInfos}>
                    <div className={classes.propertyInfosContent}>
                      <div className={classes.propertyInfosDetails}>
                        <div>
                          <span className={classes.propertyTitle}>
                            {imovel.nome}
                          </span>
                        </div>
                        <div className={classes.divSubtitle}>
                          <span className={classes.propertySubtitle}>
                            {`${imovel.localizacao.rua}, ${imovel.localizacao.numero}`}
                          </span>
                        </div>
                        <div className={classes.propertyDetailsPlus}>
                          {typeof imovel.quartos !== 'undefined' &&
                            <>
                              <div className={classes.DetailsPlusF}>
                                <div className={classes.divPlusIcon}>
                                  <img
                                    className={classes.plusIcon}
                                    src={iconQuartos}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <span className={classes.spanPlus}>
                                    {imovel.quartos}
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                          {typeof imovel.areaLazer !== 'undefined' && parseInt(imovel.areaLazer) > 0 &&
                            <>
                              <div className={classes.DetailsPlus}>
                                <div className={classes.divPlusIcon}>
                                  <img
                                    className={classes.plusIcon}
                                    src={iconPlanta}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <span className={classes.spanPlus}>
                                    Área de Lazer
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                      {/* {width > 750 && (
                        <div className={classes.propertyIconsLarge}>
                          <div className={classes.divIconsLarge}>
                            <div>
                              <img src={share} alt="" />
                            </div>
                            <div>
                              <span className={classes.spanIcons}>
                                Compartilhar
                              </span>
                            </div>
                          </div>
                          <div className={classes.divIconsLargeF}>
                            <div>
                              <img src={favorite} alt="" />
                            </div>
                            <div>
                              <span className={classes.spanIcons}>Salvar</span>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <Featured width={width} imovel={imovel} />

              <PropertyRecord imovel={imovel} />
            </div>
          </div>
        </div>
      </div>
      <DialogBrief open={open} handleClose={closeDialog} />
    </>
  );
};

export default HeaderRecord;
