import React from "react";
import { useStyles } from './styles';

const Loading = () => {

    const classes = useStyles();

    return (
        <>
            <div className={classes.loader}></div>
        </>
    );

}

export default Loading;