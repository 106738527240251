import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Loading from "../../components/Loading";
import Brief from "../../components/Brief";
import Header from "../../components/Header";
import Property from "../../components/Property";

const HomePage = () => {
  const locationHref = "/404";
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [realtor, setRealtor] = useState({});
  const [properties, setProperties] = useState([]);
  const location = useLocation()
  location.state = { page: 'Home' }

  useEffect(() => {

    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname.replace("/", "");
      if (!pathName) {
        window.location.href = locationHref;
        return false;
      };
      setName(pathName);
    }
  }, []);

  useEffect(() => {
    if (name && name !== null) {
      try {
        if (sessionStorage.getItem('realtor') == null || sessionStorage.getItem('properties') == null) throw 'Sem informações em cache.';

        const sessCorretor = JSON.parse(sessionStorage.getItem("realtor"));
        if (typeof sessCorretor[name] !== 'undefined') {
          setRealtor(sessCorretor[name]);
        } else {
          throw 'Dados de corretor não encontrados no cache ou os dados divergem do corretor selecionado';
        }

        const sessImoveis = JSON.parse(sessionStorage.getItem("properties"));
        if (typeof sessImoveis[name] !== 'undefined') {
          setProperties(sessImoveis[name]);
        } else {
          throw 'Dados dos imóveis não encontrados no cache ou os dados divergem do corretor selecionado';
        }


      } catch (error) {
        console.log(error);
        buscaPorURL();
      }

    }
  }, [name])

  useEffect(() => {
    if (Object.keys(realtor).length && properties.length) {
      setLoading(false);
    }
  }, [realtor, properties]);

  useEffect(() => {
    if (properties.length) {
      window.dataLayer.push({
        'event': 'dados-imoveis',
        'pagepath': window.location.pathname,
        'pageType': 'home',
        'imoveis': properties.map(property => {
          return {
            'id': property.imovelId,
            'nome': property.nome,
            'bairro': property.localizacao.bairro,
            'cidade': property.localizacao.cidade,
            'estado': property.localizacao.estado,
            'estagio': property.status,
            'tipo': property.bi.tipo,
            'areaprivativa': [property.bi.areaPrivativa[0], property.bi.areaPrivativa[1]],
            'dormitorios': [property.bi.dormitorios[0], property.bi.dormitorios[1]],
            'suites': [property.bi.suites[0], property.bi.suites[1]],
            'vagas': [property.bi.vagas[0], property.bi.vagas[1]],
            'valor': [property.bi.valor[0], property.bi.valor[1]],
            'portfolio': property.bi.portfolio
          }
        })
      })
    }


  }, [properties]);

  const buscaPorURL = async () => {
    await axios.get(`${process.env.REACT_APP_API_HOST}/apicyrela/api/FCommerce/FCCorretor/busca-por-url?url=${name}`, { timeout: 30000 })
      .then(res => {
        if (typeof res.data !== 'undefined' && typeof res.data.success !== 'undefined' && res.data.success) {
          if (typeof res.data.corretor !== 'undefined') {
            setRealtor(res.data.corretor);
            let sessCorretor = {};
            sessCorretor[name] = res.data.corretor;
            sessionStorage.setItem("realtor", JSON.stringify(sessCorretor));
          }

          if (typeof res.data.imoveis !== 'undefined') {
            setProperties(res.data.imoveis);
            let sessImoveis = {};
            sessImoveis[name] = res.data.imoveis;
            sessionStorage.setItem("properties", JSON.stringify(sessImoveis));
          }
          setLoading(false);
        } else {
          window.location.href = locationHref;
        }
      });
  }

  return (
    <>
      {loading
        ? <Loading />
        : <>
          {realtor &&
            <Header corretor={realtor} />
          }
          {properties &&
            <Property name={name} imoveis={properties} />
          }
          { realtor &&
            <Brief corretor={realtor} showTestimonials={!!realtor.depoimentos.length} />
          }
        </>
      }
    </>
  );
};

export default HomePage;
