/* eslint-disable react/forbid-prop-types */
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Carousel from 'react-grid-carousel';
import { Link } from 'react-router-dom';

import bullets from '../../../assets/images/bullets.svg';
import iconPlanta from '../../../assets/images/icon-planta.png';
import iconQuartos from '../../../assets/images/icon-quartos.png';
import iconVaga from '../../../assets/images/icon-vaga.png';
import iconVaranda from '../../../assets/images/varanda.svg';
import { useStyles } from './styles';

const Property = ({ name, imoveis }) => {
  const classes = useStyles();

  const [age, setAge] = useState('');
  const [categActive, setCategActive] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [statusList, setStatusList] = useState({});

  const handleChange = (event) => {

    window.dataLayer.push({
      'event': 'sendEvent',
      'event-action': 'select',
      'event-value': `imoveis:ordenar:${event.target.value}`
    })


    setCategActive('');
    setAge(event.target.value);

    filterBy(event.target.value, true);

  };

  const categChange = (categ) => {
    setCategActive(categ);
    filterBy(categ, false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (Array.isArray(imoveis) && imoveis.length) {
      setFilteredProperties(imoveis);
      getPropertiesStatus();
    }

  }, [imoveis]);

  const filterBy = (categ, order = false) => {
    if (categ == '') {
      setFilteredProperties(imoveis);
    } else {
      let resFiltered = imoveis.filter((imovel) => {
        if (typeof imovel.status !== 'undefined' && imovel.status == categ) {
          return imovel;
        }
      });

      if (order) {
        let resUnFiltered = imoveis.filter((imovel) => {
          if (typeof imovel.status !== 'undefined' && imovel.status != categ) {
            return imovel;
          }
        });

        let temp = [...resFiltered, ...resUnFiltered];
        resFiltered = temp;
      }

      setFilteredProperties(resFiltered);
    }

  }

  const getPropertiesStatus = () => {
    let statuses = {};
    for (var i = 0; i < imoveis.length; i++) {
      statuses[imoveis[i].status] = true;
    }

    setStatusList(statuses);
  }

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)} Mil`;
    if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)} Milhão`;
    if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)} Bilhão`;
    if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`;
    return n;
  };

  const setBackground = (imovel) => {
    let color = '#FFD200';

    if (typeof imovel.status !== 'undefined') {
      if (imovel.status === 'Em Obras') {
        color = '#960064';
      } else if (imovel.status === 'Lançamento') {
        color = '#2FDADA';
      }
    }

    return color;
  };

  const MyDot = ({ isActive }) => (
    <div
      style={{
        marginTop: '50px',
      }}>
      <span
        style={{
          display: 'inline-block',
          borderRadius: '50%',

          height: isActive ? '8px' : '8px',
          width: isActive ? '8px' : '8px',
          background: isActive ? '#2FDADA' : '#999',
        }}
      />
    </div>
  );

  MyDot.propTypes = {
    isActive: PropTypes.any.isRequired,
  };

  return (
    <div className={classes.containerP}>
      <div className={classes.content}>
        <div className={classes.divTitle}>
          <div className={classes.divSpanTitle}>
            <span className={classes.spanTitle}>Imóveis em</span>
          </div>
        </div>
        {width <= 575 ? (
          <div
            style={{
              marginBottom: '30px',
              width: '100%',
              maxWidth: '575px',
              // border: '1px solid red',
            }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <NativeSelect
                // native
                disableUnderline
                value={age}
                onChange={handleChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                  style: {
                    border: '1px solid #2FDADA',
                    paddingLeft: '10px',
                    height: '41px',
                  },
                }}>
                <option value={''}>Ordenar</option>
                {Object.keys(statusList).map((key, index) => (
                  <option key={index} value={key}>{key}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
        ) : (
            <div className={classes.contentCategsFilters}>
              <div className={classes.divCategsFilters}>
                <div className={classes.contentCategs}>
                  <div className={classes.divContentCategs}>
                    <div gtm-action="click" gtm-value="imoveis:filtrar:todos">
                      <span onClick={() => { categChange(''); }}
                        className={
                          categActive === ''
                            ? classes.spanCategActive
                            : classes.spanCategs
                        }>Todos</span>
                    </div>
                    {Object.keys(statusList).map((key, index) => (
                      <div key={index} gtm-action="click" gtm-value={`imoveis:filtrar:${key.replace(' ', '-').replace('ç', 'c').toLowerCase()}`}>
                        <span onClick={() => { categChange(key); }} className={
                          categActive === key
                            ? classes.spanCategActive
                            : classes.spanCategs
                        }>{key}</span>
                      </div>
                    ))}

                  </div>
                </div>
                <div className={classes.divFormControl}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <NativeSelect
                      // native
                      disableUnderline
                      value={age}
                      onChange={handleChange}
                      inputProps={{
                        name: 'age',
                        id: 'filled-age-native-simple',
                        style: {
                          border: '1px solid #2FDADA',
                          paddingLeft: '10px',
                          height: '41px',
                        },
                      }}>
                      <option value={''}>Ordenar</option>
                      {Object.keys(statusList).map((key) => (
                        <option value={key} gtm-action="click" gtm-value={`imoveis:ordenar:${key.replace(' ', '-').replace('ç', 'c').toLowerCase()}`}>{key}</option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Carousel
            cols={4}
            rows={2}
            gap={11}
            dot={MyDot}
            loop
            showDots
            hideArrow
            mobileBreakpoint={576}
            responsiveLayout={[
              {
                breakpoint: 1200,
                cols: 3,
              },
              {
                breakpoint: 990,
                cols: 2,
              },
            ]}
            containerStyle={{ width: '100%', maxWidth: '1180px' }}>

            {filteredProperties.map((imovel) => (
              < Carousel.Item key={Math.random()} >

                <div
                  className={
                    width <= 575
                      ? classes.carouelItemLarge
                      : classes.carouelItem
                  }>
                  <div
                    style={{
                      backgroundColor: `${setBackground(imovel)}`,
                    }}
                    className={classes.slideTitle}>
                    {imovel.status}
                  </div>

                  {typeof imovel.galeria !== 'undefined' && imovel.galeria !== null && imovel.galeria.length > 0 && typeof imovel.galeria[0].imgOriginal !== 'undefined' &&
                    <>
                      <div
                        className={
                          width <= 575
                            ? classes.divImgslideLarge
                            : classes.divImgslide
                        }

                        style={{
                          backgroundImage: `url(${imovel.galeria[0].imgOriginal}?d=270x250)`,
                        }}
                      />
                    </>
                  }
                  <div className={classes.divInfoslide}>
                    {typeof imovel.nome !== 'undefined' && imovel.nome &&
                      <>
                        <div className={classes.divImoveName}>
                          <span className={classes.spanImoveName}>
                            {imovel.nome}
                          </span>
                        </div>
                      </>
                    }
                    <div className={classes.divAllIcons}>
                      {width <= 575 ? (
                        <>
                          {typeof imovel.areaPrivativaM2 !== 'undefined' && !!imovel.areaPrivativaM2 &&
                            <>
                              <div className={classes.divIconsInfos}>
                                <div className={classes.divIcons}>
                                  <img
                                    className={classes.icon}
                                    src={iconPlanta}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <span className={classes.spanInfoIcons}>
                                    {imovel.areaPrivativaM2}m²
                                  </span>
                                </div>
                              </div>
                            </>
                          }


                          {typeof imovel.quartos !== 'undefined' && imovel.quartos &&
                            <>
                              <div className={classes.divIconsInfos}>
                                <div className={classes.divIcons}>
                                  <img
                                    className={classes.icon}
                                    src={iconQuartos}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <span className={classes.spanInfoIcons}>
                                    {imovel.quartos}
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        </>
                      ) : (
                          <>
                            {typeof imovel.quartos !== 'undefined' && !!(imovel.quartos) &&
                              <>
                                <div className={classes.divIconsInfos}>
                                  <div className={classes.divIcons}>
                                    <img
                                      className={classes.icon}
                                      src={iconQuartos}
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <span className={classes.spanInfoIcons}>
                                      {imovel.quartos}
                                    </span>
                                  </div>
                                </div>
                              </>
                            }
                            {typeof imovel.areaPrivativaM2 !== 'undefined' && !!Number(imovel.areaPrivativaM2) &&
                              <>
                                <div className={classes.divIconsInfos}>
                                  <div className={classes.divIcons}>
                                    <img
                                      className={classes.icon}
                                      src={iconPlanta}
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <span className={classes.spanInfoIcons}>
                                      {imovel.areaPrivativaM2}m²
                                  </span>
                                  </div>
                                </div>
                              </>
                            }
                          </>
                        )}

                      {typeof imovel.vagasGaragem !== 'undefined' && !!imovel.vagasGaragem && !!Number(imovel.vagasGaragem) > 0 &&
                        <>
                          <div className={classes.divIconsInfos}>
                            <div className={classes.divIcons}>
                              <img
                                style={{ width: '100%' }}
                                src={iconVaga}
                                alt=""
                              />
                            </div>
                            <div>
                              <span className={classes.spanInfoIcons}>
                                {imovel.vagasGaragem} {!!Number(imovel.vagasGaragem) > 1 ? 'vagas' : 'vaga'}
                              </span>
                            </div>
                          </div>
                        </>
                      }
                      {typeof imovel.opcaoDeVaranda !== 'undefined' && !!imovel.opcaoDeVaranda &&
                        <>
                          <div className={classes.divIconsInfos}>
                            <div className={classes.divIcons}>
                              <img
                                style={{ width: '100%' }}
                                src={iconVaranda}
                                alt=""
                              />
                            </div>
                            <div>
                              <span className={classes.spanInfoIcons}>
                                Opção de varanda
                              </span>
                            </div>
                          </div>
                        </>
                      }
                    </div>

                    {typeof imovel.precoMinimo !== 'undefined' && parseFloat(imovel.precoMinimo) > 0 &&
                      <>
                        <div className={classes.divSinceFrom}>
                          <span className={classes.spanSinceFrom}>a partir de</span>
                          <span className={classes.spanSinceFromValue}>
                            R${formatCash(imovel.precoMinimo)}
                          </span>
                        </div>
                      </>
                    }

                    <div style={{ width: '100%' }}>
                      <Link to={`/${name}/${imovel.urlAmigavel}`}>
                        <button className={classes.buttonInfo} type="submit">
                          conheça
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <div className={classes.divButton}>
          <button className={classes.buttonInfoShowAll} type="submit" onClick={() => { categChange(''); }} gtm-action="click" gtm-value="imoveis:ver-todos">
            ver todos
          </button>
        </div>
      </div>
    </div >
  );
};

export default Property;
