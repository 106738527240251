/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Input = (props) => {
  const classes = useStyles();
  const {
    id,
    label,
    placeholder,
    defaultValue,
    error,
    helperText,
    type,
    value,
    rows,
    multiline,
    onChange,
    textArea,
  } = props;

  return (
    <TextField
      id={id}
      type={type}
      label={label}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      className={!textArea ? classes.input : classes.inputTextArea}
      defaultValue={defaultValue}
      value={value}
      rows={rows}
      disableUnderline
      focused={false}
      multiline={multiline}
      onChange={onChange}
      variant={textArea ? 'outlined' : 'standard'}
      InputProps={{
        focused: false,
        className: `${textArea ? classes.textArea : classes.inputContent}`,
        endAdornment: !textArea && (
          <InputAdornment position="start">
            <PersonOutlineOutlinedIcon className={classes.inputIcon} />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        focused: false,
        className: classes.inputLabel,
      }}
    />
  );
};

Input.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  defaultValue: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  type: PropTypes.any,
  value: PropTypes.any,
  rows: PropTypes.any,
  multiline: PropTypes.any,
  onChange: PropTypes.any,
  textArea: PropTypes.any,
};

export default Input;
