import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import React, { useState, useEffect } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import SwipeableViews from 'react-swipeable-views';

import './styles.css';
import 'react-multi-carousel/lib/styles.css';
import Back from '../../../assets/images/back.svg';
import emailWhite from '../../../assets/images/emailWhite.svg';
import facebookWhite from '../../../assets/images/facebookWhite.svg';
import Front from '../../../assets/images/front.svg';
import graphic from '../../../assets/images/graphic.png';
import instagramWhite from '../../../assets/images/instagramWhite.svg';
import linkedinWhite from '../../../assets/images/linkedinWhite.svg';
import logoWhite from '../../../assets/images/logoWhite.svg';
import twitterWhite from '../../../assets/images/twitterWhite.svg';
import whatsWhite from '../../../assets/images/whats-branca.svg';
import { useStyles } from './styles';

const styles = {
  root: {
    padding: '0 24px',
    width: '100%',
    maxWidth: '900px',
  },
  rootLarge: {
    padding: '0 150px',
    width: '100%',
    maxWidth: '1140px',
  },
  slideContainer: {
    padding: '0px 10px',
    // minWidth: '272px',
    // maxWidth: '272px',
    height: '100%',
    minHeight: '224px',
    maxHeight: '224px',
  },
  slide: {
    padding: 15,
    minHeight: 100,
    backgroundColor: '#fff',
    color: '#000',
  },
  slideContainerLarge: {
    backgroundColor: '#fff',
    padding: '0px 12px',
    // maxWidth: '300px',
    height: '100%',
    minHeight: '184px',
    maxHeight: '184px',
  },
  contentThumbLarge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    paddingTop: '25px',
  },
  divThumbLarge: {
    width: '100%',
    height: '100%',
    maxWidth: '67px',
    maxHeight: '67px',
  },
  thumbLarge: {
    width: '100%',
    height: 'auto',
  },
  divQuotesLarge: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  quotesLarge: {
    width: '33px',
    height: '18px',
    fill: '#960064',
  },
  divBriefLarge: {
    width: '100%',
    maxWidth: '184px',
    marginBottom: '15px',
  },
  spanBriefLarge: {
    fontFamily: 'Graphie',
    fontSize: '12px',
    color: '#2B2823',
    letterSpacing: 0,
    lineHeight: '16px',
    display: 'table',
    textAlign: 'justify',
  },
  spanBriefNameLarge: {
    fontFamily: 'Graphie',
    fontSize: '14px',
    color: '#2B2823',
    letterSpacing: 0,
    lineHeight: '24px',
    fontWeight: 'bold',
    display: 'table',
    textAlign: 'justify',
  },
};

const Brief = ({ corretor, showTestimonials }) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3 },
    { width: 1450, itemsToShow: 3 },
    { width: 1750, itemsToShow: 3 },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img className={classes.imgBack} src={Back} alt="" />
      ) : (
          <img className={classes.imgFront} src={Front} alt="" />
        );
    return (
      <div
        style={{
          display: 'flex',
        }}
        aria-hidden="true"
        onClick={onClick}
        disabled={isEdge}>
        {pointer}
      </div>
    );
  };

  return (
    <>
      {showTestimonials && <div className={classes.briefContent}>
        <>
          <div className={classes.contentTitle}>
            <div className={classes.divContentTitle}>
              <span className={classes.spanTitle}>Depoimentos</span>
            </div>
          </div>
          {width <= 575 ? (
            <SwipeableViews
              resistance
              style={styles.root}
              slideStyle={styles.slideContainer}>
              { typeof corretor !== 'undefined' && typeof corretor.depoimentos !== 'undefined' && corretor.depoimentos.length > 0 &&
                <>
                  {corretor.depoimentos.map((item) => (
                    <div key={Math.random()} style={{ ...styles.slide }}>
                      <div className={classes.contentThumb}>
                        <div className={classes.divThumb}>
                          <img className={classes.thumb} src={item.foto} alt="" />
                        </div>
                        <div>
                          <FormatQuoteIcon className={classes.quotes} />
                        </div>
                      </div>
                      <div>
                        <span className={classes.spanBrief}>{item.depoimento}</span>
                      </div>
                      <div className={classes.spanBriefName}>
                        <span>{item.nome}</span>
                      </div>
                    </div>
                  ))}
                </>
              }
            </SwipeableViews>
          ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  maxHeight: '184px',
                }}>
                <div
                  style={{
                    width: '100%',
                    maxWidth: '1340px',
                    height: '100%',
                    maxHeight: '184px',
                  }}>
                  <Carousel breakPoints={breakPoints} renderArrow={myArrow}>
                    {typeof corretor !== 'undefined' && typeof corretor.depoimentos !== 'undefined' && corretor.depoimentos.length > 0 &&
                      <>
                        {corretor.depoimentos.map((item) => (

                          <div
                            key={Math.random()}
                            className={classes.contentBriefLarge}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              alignItems: 'flex-start',
                              height: '184px',
                              width: '100%',
                              minWidth: '368px',
                              maxWidth: '368px',
                              backgroundColor: '#fff',
                              paddingTop: '25px',
                              fontSize: '4em',
                            }}>
                            <div
                              style={{
                                width: '100%',
                                maxWidth: '75px',
                                height: '75px',
                              }}>
                              <img
                                style={{
                                  width: '100%',
                                }}
                                src={item.foto}
                                alt=""
                              />
                            </div>
                            <div>
                              <div style={{ width: '100%', maxWidth: '184px' }}>
                                <span
                                  style={{
                                    fontFamily: 'Graphie',
                                    fontSize: '12px',
                                    color: '#2B2823',
                                    letterSpacing: 0,
                                    lineHeight: '16px',
                                    display: 'table',
                                    textAlign: 'justify',
                                  }}>
                                  {item.depoimento}
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{
                                    marginTop: '15px',
                                    fontFamily: 'Graphie',
                                    fontSize: '14px',
                                    color: '#2B2823',
                                    letterSpacing: 0,
                                    lineHeight: '24px',
                                    fontWeight: 'bold',
                                    display: 'table',
                                    textAlign: 'justify',
                                  }}>
                                  {item.nome}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                              }}>
                              <FormatQuoteIcon
                                style={{
                                  fontSize: '33px',
                                  fill: '#960064',
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    }
                  </Carousel>
                </div>
              </div>
            )}

          <div className={classes.divGraphic}>
            <img className={classes.imgGraphic} src={graphic} alt="" />
          </div>
        </>
      </div>}
      <div className={classes.footerContent}>
        <div className={classes.divFooterContent}>
          <div className={classes.footerContainer}>
            <div className={classes.containerInfos}>
              <div className={classes.divInfo}>
                <span className={classes.spanName}>
                  {corretor.nome}
                </span>
              </div>
              <div className={classes.divInfo}>
                <span className={classes.spanCreci}>
                  creci {corretor.creci}
                </span>
              </div>
              <div className={classes.divInfo}>
                <div className={classes.divInfoEmail}>
                  <div className={classes.divInfoEmailImg}>
                    <img src={emailWhite} alt="" />
                  </div>
                  <div className={classes.divInfoEmail}>
                    <span className={classes.spanEmail}>
                      {corretor.email}
                    </span>
                  </div>
                </div>
              </div>
              {corretor.telefones.map((item, index) => {
                return (
                  <div key={index} className={classes.divInfo}>
                    <div className={classes.divInfoEmail}>
                      <div className={classes.divInfoEmailImg}>
                        <img src={whatsWhite} alt="" />
                      </div>
                      <div>
                        <span className={classes.spanPhone}>
                          {item.telefone}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
              }
            </div>
            <div className={classes.containerMedias}>
              {typeof corretor.facebook !== 'undefined' && corretor.facebook !== null && corretor.facebook.trim().length > 0 &&
                <a gtm-action="click" gtm-value="corretor:redes-sociais:facebook" className={classes.iconsSocialMedia} href={`https://facebook.com/${corretor.facebook}`} target="_blank" rel="noreferrer"  >
                  <img src={facebookWhite} alt="" />
                </a>
              }

              {typeof corretor.instagran !== 'undefined' && corretor.instagran !== null && corretor.instagran.trim().length > 0 &&
                <a gtm-action="click" gtm-value="corretor:redes-sociais:instagram" className={classes.iconsSocialMedia} href={`https://instagram.com/${corretor.instagran}`} target="_blank" rel="noreferrer" >
                  <img src={instagramWhite} alt="" />
                </a>
              }


              {typeof corretor.linkedin !== 'undefined' && corretor.linkedin !== null && corretor.linkedin.trim().length > 0 &&
                <a gtm-action="click" gtm-value="corretor:redes-sociais:linkedin" className={classes.iconsSocialMedia} href={`https://linkedin.com/${corretor.linkedin}`} target="_blank" rel="noreferrer" >
                  <img src={linkedinWhite} alt="" />
                </a>
              }

              {typeof corretor.twitter !== 'undefined' && corretor.twitter !== null && corretor.twitter.trim().length > 0 &&
                <a gtm-action="click" gtm-value="corretor:redes-sociais:twitter" className={classes.iconsSocialMedia} href={`https://twitter.com/${corretor.twitter}`} target="_blank" rel="noreferrer" >
                  <img src={twitterWhite} alt="" />
                </a>
              }

            </div>
          </div>
        </div>
        <hr className={classes.hr} />
        <div className={classes.divFooterContent2}>
          <div className={classes.footerLogo}>
            <img src={logoWhite} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brief;
