import React, { useState, useEffect } from 'react';

import differential from '../../../assets/images/icon-diferencial.svg';
import { useStyles } from './styles';

const Differential = ({ imovel }) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);
  const [diff, setDiff] = useState([]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const breakDiffByLines = () => {
    let tempDiff = [];
    if (typeof imovel.localizacao.diferenciais !== 'undefined' && Array.isArray(imovel.localizacao.diferenciais) && imovel.localizacao.diferenciais.length) {
      let tempLine = [];
      let tempDouble = [];
      for (var i in imovel.localizacao.diferenciais) {
        let d = imovel.localizacao.diferenciais[i];
        let j = parseInt(i) + 1;
        tempLine.push(d);
        if (j % 4 === 0 || j === imovel.localizacao.diferenciais.length) {
          let tempPair = [];
          for (var k in tempLine) {
            let l = parseInt(k) + 1;
            tempPair.push(tempLine[k]);
            if (l % 2 === 0 || l === tempLine.length) {
              tempDouble.push(tempPair);
              tempPair = [];
            }
          }
          tempDiff.push(tempDouble);
          tempLine = [];
          tempDouble = [];
        }
      }
    }

    /*
    cortar para aparecer somente 8 itens (devido ao layout estar quebrado)
    * /
    let cut = [];
    if (typeof tempDiff[0] !== 'undefined') cut.push(tempDiff[0]);
    if (typeof tempDiff[1] !== 'undefined') cut.push(tempDiff[1]);
    setDiff(cut);
    /**/
    setDiff(tempDiff);
    /**/
  };

  useEffect(() => {
    breakDiffByLines();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.contentDifferential}>

          <div className={classes.divLocalization}>
            <div className={classes.imgLocalization}>
              <img className={classes.img} src={differential} alt="" />
            </div>
            <div>
              <span className={classes.spanLocalization}>Diferenciais</span>
            </div>
          </div>

          <div className={classes.boxIcons}>
            {diff.map((lin, index) => {
              return (
                <>
                  <div key={index} className={classes.divDifferentialAllIcons}>

                    {lin.map((col, ind) => {
                      return (
                        <>
                          <div key={ind} className={classes.divDifferential}>

                            {col.map((item, i) => {
                              return (
                                <>
                                  <div key={i} style={{ marginBottom: '25px' }}>
                                    <div>
                                      <img
                                        className={classes.iconDifferential}
                                        src={item.urlImagem}
                                        alt=""
                                      />
                                    </div>
                                    <div className={classes.divSpanDifferential}>
                                      <span className={classes.spanDifferential}>
                                        {item.breveDescricao}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      )
                    })}

                  </div>
                </>
              )
            })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Differential;
