import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  // container: {
  //   backgroundColor: '#f2f2f2',
  //   height: '25px',

  //   '@media (min-width: 576px)': {
  //     height: '860px',
  //   },
  // },
});
