import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import Dialog from '@material-ui/core/Dialog';

// import icoGallerysvg from '../../../assets/images/ico-gallery.svg';
// import shareWhite from '../../../assets/images/icon-share-white.svg';
import './styles.css'

const SubPropertyRecord = ({ galeria, classes, open, handleCloseModal }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState(window.width)

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleClose = () => {

    handleCloseModal(false)
  }

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const renderItems = galeria.map((pic) => {

    return (
      <div
        key={Math.random()}
        style={{
          width: '100%',
          maxWidth: '740px',
          height: '360px',
          padding: '0 4px',
        }}>
        <img
          style={{
            width: '100%',
            height: '320px',
            borderRadius: '5px',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
          src={`${pic.imgOriginal}?d=740x370`}
          alt=""
        />
        <p>{pic.legenda}</p>
      </div>
    );
  });

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="galery-dialog-custom"
      open={isOpen}
      maxWidth="md">
      <div className="close-galery" onClick={handleClose}>
        X
      </div>
      <div style={{ padding: '50px' }}>
        <Carousel
          centerMode
          infiniteLoop
          dynamicHeight={false}
          emulateTouch
          swipeScrollTolerance={50}
          showThumbs={false}
          showArrows={false}
          dots={false}

          showIndicators={width > 575}>
          {renderItems}
        </Carousel>
      </div>
    </Dialog >
  );
};

export default SubPropertyRecord;
