import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  containerFeatured: {
    position: 'absolute',
    marginTop: '40px',
    paddingLeft: 0,

    '@media (min-width: 576px)': {
      paddingLeft: '5px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '752px',
  },
  contentFeatured: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  contentDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '290px',
  },
  contentDivRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    maxWidth: '290px',
  },
  divLeft: {
    position: 'absolute',
    // marginLeft: '-31px',
  },
  divRigth: {
    position: 'absolute',
    // marginLeft: '-31px',

    '@media (min-width: 576px)': {
      marginLeft: '-26px',
    },
  },
  img: {
    width: '70px',
    height: '70px',

    '@media (min-width: 576px)': {
      width: '58px',
      height: '58px',
    },
  },
  divLeftContent: {
    width: '257px',
    height: '70px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '26px',

    '@media (min-width: 576px)': {
      width: '202px',
      height: '57px',
    },
  },
  divRightContent: {
    width: '254px',
    height: '70px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '33px',
  },
  divLeftContentItbi: {
    width: '257px',
    height: '70px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#960064',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '26px',

    '@media (min-width: 576px)': {
      width: '202px',
      height: '57px',
    },
  },
  divRightContentItbi: {
    width: '254px',
    height: '70px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#960064',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '33px',

    '@media (min-width: 576px)': {
      width: '202px',
      height: '57px',
    },
  },
  pDesc: {
    margin: 0,
    padding: 0,
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: 400,
    color: '#58545B',
    letterSpacing: 0,
    textAlign: 'initial',
    lineHeight: '24px',

    '@media (min-width: 576px)': {
      fontSize: '13px',
      lineHeight: '20px',
    },
  },
  pDescItbi: {
    margin: 0,
    padding: 0,
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: 700,
    color: '#fff',
    letterSpacing: 0,
    textAlign: 'initial',
    lineHeight: '24px',

    '@media (min-width: 576px)': {
      fontSize: '13px',
      lineHeight: '19px',
    },
  },
  pValue: {
    margin: 0,
    padding: 0,
    fontFamily: 'Graphie',
    fontSize: '24px',
    fontWeight: 700,
    color: '#363338',
    letterSpacing: 0,
    textAlign: 'initial',
    lineHeight: '32px',

    '@media (min-width: 576px)': {
      fontSize: '20px',
      lineHeight: '25px',
    },
  },
});
