import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    marginTop: '630px',

    '@media (min-width: 576px)': {
      marginTop: '230px',
    },
  },
  contentImages: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '14px',

    '@media (min-width: 576px)': {
      marginBottom: '20px',
    },
  },
  contentImageLarge: {
    width: '272px',
    margin: '0 8px',
    position: 'relative',

    '@media (min-width: 576px)': {
      width: '100%',
      maxWidth: '501px',
      margin: '0 8px',
      position: 'relative',
    },
  },
  img: {
    width: '100%',
    height: '272px',

    '@media (min-width: 576px)': {
      height: 'auto',
    },
  },
  iconShare: {
    position: 'absolute',
    left: '86%',
    bottom: '15%',

    '@media (min-width: 576px)': {
      bottom: '15%',
      left: '93%',
    },
  },
  iconGallery: {
    position: 'absolute',
    left: '86%',
    bottom: '90%',
    cursor: 'pointer',
    '@media (min-width: 576px)': {
      bottom: '90%',
      left: '93%',
    },
  },
});
