import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  input: {
    minWidth: '100%',
    marginBottom: '15px',
    opacity: 1,
    '& ::-webkit-input-placeholder': {
      color: '#960064',
      opacity: '1 !important',
    },
  },
  inputTextArea: {
    minWidth: '100%',
    marginBottom: '15px',
    opacity: 1,
    '& ::-webkit-input-placeholder': {
      color: '#960064',
      opacity: '.5 !important',
    },
  },
  inputContent: {
    color: '#960064',
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '24px',
    paddingLeft: '8px',
  },
  textArea: {
    color: '#960064',
    fontFamily: 'Graphie',
    fontSize: '16px',
    fontWeight: 400,
    minHeight: '80px',
  },
  inputLabel: {
    color: '#7a7a7a',
    fontSize: '12px',
    letterSpacing: '1px',
  },
  inputIcon: {
    fontSize: '16px',
  },
});
