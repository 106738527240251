import { makeStyles } from '@material-ui/core';

import Loading from '../../../assets/images/loading.svg';

export const useStyles = makeStyles({
  loader: {
      position: 'fixed',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      backgroundImage: `url(${Loading})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
  }

});
