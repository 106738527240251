import { makeStyles } from '@material-ui/core';

import lozalization from '../../../assets/images/localizacao.png';
import smLozalization from '../../../assets/images/sm-localizacao.png';

export const useStyles = makeStyles({
  container: {
    backgroundColor: '#f2f2f2',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    display: 'flex',
    width: '100%',
    maxWidth: '1140px',
    justifyContent: 'flex-end',
  },
  contentBanner: {
    width: '100%',
    maxWidth: '750px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divSpanTitle: {
    width: '100%',
    maxWidth: '272px',

    '@media (min-width: 576px)': {
      maxWidth: '750px',
    },
  },
  spanTitle: {
    fontFamily: 'Graphie',
    fontSize: '32px',
    color: '#960064',
    letterSpacing: 0,
    lineHeight: '40px',
    fontWeight: 700,
    display: 'table',
    textAlign: 'initial',

    '@media (min-width: 576px)': {
      fontSize: '40px',
      lineHeight: '48px',
    },
  },
  spanSubtitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '24px',
    fontWeight: 300,
    display: 'table',
    textAlign: 'initial',
  },
  divLocalization: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '272px',
    marginTop: '36px',

    '@media (min-width: 576px)': {
      maxWidth: '750px',
    },
  },
  img: {
    width: '100%',
  },
  imgLocalization: {
    marginRight: '20px',
  },
  spanLocalization: {
    fontFamily: 'Graphie',
    fontSize: '24px',
    color: '#58545B',
    letterSpacing: 0,
    lineHeight: '40px',
    fontWeight: 700,
    display: 'table',
    textAlign: 'initial',
  },
  divBanner: {
    width: '100vw',
    // maxWidth: '320px',
    display: 'flex',
    flexDirection: 'row',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    position: 'relative',

    '@media (min-width: 576px)': {
      maxWidth: '752px',
      marginTop: '48px',
    },
  },
  bannerPic: {
    backgroundImage: `url(${smLozalization})`,
    width: '100%',
    height: '320px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    '@media (min-width: 576px)': {
      backgroundImage: `url(${lozalization})`,
      width: '750px',
      height: '248px',
    },
  },
  divSpanBanner: {
    position: 'absolute',
    padding: '0 30px',
    marginTop: '-80px',
    maxWidth: '320px',

    '@media (min-width: 576px)': {
      marginTop: 0,
      maxWidth: '650px',
    },
  },
  spanBanner: {
    fontFamily: 'Graphie',
    fontSize: '30px',
    color: '#FFF',
    letterSpacing: 0,
    lineHeight: '40px',
    fontWeight: 700,
    display: 'table',
    textAlign: 'initial',

    '@media (min-width: 576px)': {
      fontSize: '40px',
      lineHeight: '48px',
    },
  },
  buttonInfoLocalization: {
    width: '100%',
    minWidth: '272px',
    height: '100%',
    minHeight: '56px',
    maxHeight: '56px',
    boxShadow: '0px 16px 8px -11px rgba(0, 0, 0, 0.19)',
    backgroundColor: '#2FDADA',
    color: '#fff',
    border: 'none',
    textTransform: 'uppercase',
    borderRadius: '5px',
    fontFamily: 'Graphie',
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '1.4px',
    lineHeight: '24px',
    cursor: 'pointer'
  },
});
